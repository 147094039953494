import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, timer, from, BehaviorSubject } from 'rxjs';
import { mergeMap, filter, take, map } from 'rxjs/operators';

// Services
import { CognitoService } from './cognito.service';

@Injectable()
export class PollingService {

  startPolling: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  pollObservable: Observable<any>;
  constructor(
    private cognitoService: CognitoService,
    private http: HttpClient,
  ) {
  }


  initiateChatPolling(reassign: boolean = false) {
    if (this.pollObservable && !reassign) {
      return this.pollObservable;
    }
    this.pollObservable = timer(0, 1000 * 10)
      .pipe(
        mergeMap(() => from(this.cognitoService.isAuthenticated())),
        mergeMap(() => this.getData()),
        filter((response: any) => {
          return Object.keys(response).length > 0;
        }),
        take(1)
      );
    return this.pollObservable;
  }

  getPollingStatus() {
    const pollObserver = timer(0, 1000 * 10)
      .pipe(
        mergeMap(() => from(this.cognitoService.isAuthenticated())),
        mergeMap(() => this.getData()),
        filter((response: any) => {
          return Object.keys(response).length === 0;
        }),
        take(1)
      );
    return pollObserver;
  }

  getData() {
    return this.http.get('chat/poll', { params: { 'integration': 'true' } })
      .pipe(
        map((response: any) => response.data)
      );
  }
}
