import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

// Constants
import { AppConfigConstants } from '../../common/constants';

// Services
import { CognitoService } from '../../core/services/cognito.service';
import { ValidationService } from '../../core/services/validation.service';
import { SpinnerService } from '../../core/spinner/spinner.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { VERSION } from 'src/environments/version';
import { AppToasterService } from 'src/app/core/services/app-toaster.service';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  forgetPasswordFormGroup: FormGroup;
  showForgotPassword = true;
  apiErrorMsg = '';
  version = VERSION.version;

  constructor(
    private builder: FormBuilder,
    private router: Router,
    private validationService: ValidationService,
    private spinnerService: SpinnerService,
    private cognitoService: CognitoService,
    private authService: AuthService,
    private apptoaster: AppToasterService) { }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.forgetPasswordFormGroup = this.builder.group({
      'email': new FormControl('', [Validators.required, this.validationService.emailValidator, this.validationService.hasWhiteSpace]),
    });
  }

  onSubmit() {
    if (this.validationService.validateForm(this.forgetPasswordFormGroup)) {
      this.spinnerService.change(true);
      const forgetForm = this.forgetPasswordFormGroup.getRawValue();

      // this.cognitoService.forgotPassword(forgetForm.email)
      //   .then(this.success, this.forgetError);
      this.authService.forgotPassword(forgetForm.email)
        .subscribe(result => {
          this.success();
        }, error => {
          this.spinnerService.change(false);
          this.apiErrorMsg = error.error.meta.message;
          // if (error.error.meta.message === 'user not found') {
          //   this.apiErrorMsg = 'Incorrect email';
          // } else if(error.error.meta.error === AppConfigConstants.cognitoErrorResponses.notAuthorizedException){
          //   this.apiErrorMsg = error.error.meta.message;
          // } else{
          //   this.apiErrorMsg = error.error.meta.message;
          // }
          // this.forgetError;
        });
    }
  }

  success() {
    this.spinnerService.change(false);
    this.showForgotPassword = false;
  }

  forgetError = (err: Error) => {
    this.spinnerService.change(false);
    console.log('Err', err);
    if (err.name === AppConfigConstants.cognitoErrorResponses.userNotFoundException) {
      this.apiErrorMsg = 'Incorrect email';
    } else if (err.name === AppConfigConstants.cognitoErrorResponses.notAuthorizedException) {
      this.apiErrorMsg = 'An email has already been sent for change password';
    } else {
      this.apiErrorMsg = err.message;
    }
  }

  okay() {
    this.router.navigate(['./']);
  }
}
